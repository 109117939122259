



































































// @ is an alias to /src
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vTags from "@/components/Tags.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Login, LoginUserMerchants } from "@/common/login/login";

@Component({
  components: {
    "v-tags": vTags,
  },
})
export default class HelloWorld extends Vue {
  private timer: any = "";
  private userMenus: any = JSON.parse(localStorage.getItem("menus") as string);
  private defaultActive: any = this.$route.name;
  private deepLoopUtil: any;
  private setSelect(id: string, url: string): void {
    if (this.defaultActive != id) {
      this.defaultActive = id;
      this.$router.push(url);
    }
  }


  private logout: Login;
  constructor() {
    super();
    this.logout = new Login();
    this.deepLoopUtil = new DeepLoop();
  }

  // 用户名下拉菜单选择事件
  private handleCommand(command: string): void {
    if (command == "loginout") {
      clearInterval(this.timer);
      // this.logout.logout();
      localStorage.clear();
      this.$router.push("/login");
    }
  }
  // end

  @Watch("$route")
  $setMenusList(): void {
    this.defaultActive = this.$route.name;
  }
}
