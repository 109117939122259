import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import {component} from "vue/types/umd";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: '/login'
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: "登录",
        },
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/",
        name: "home",
        component: Home,
        children: [
            {
                path: "/molis_table_manage",
                name: "molis_table_manage",
                meta: {
                    title: "麦力仕球桌管理",
                },
                component: () => import("@/views/admin/molis_table_manage.vue"),
            },
            {
                path: "/molis_table_number",
                name: "molis_table_number",
                meta: {
                    title: "麦力仕球桌编号",
                },
                component: () => import("@/views/admin/molis_table_number.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

export default router;
