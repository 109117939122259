import { Axios, AxiosData } from '@/common/http/http';
import { BasicsClass } from '@/common/BasicsClass';

interface Result {
    access_token: string;
    token_type: string;
    refresh_token: string;
    id: string;
    name: string;
    showMerchantsSelectionBox: boolean;
    groupCode: string;
}

export interface login {
    phone: string;
    code: string;
}

export interface LoginUserMerchants {
    permissionMchId: string;
    id: string;
    username: string;
    password: string;
    company: string;
    name: string;
    mobile: string;
    email: string;
    mchId: string;
    status: number;
    createTime: string;
    updateTime: string;
    stopTime: string;
    createUserId: string;
    updateUserId: string;
    isAdmin: boolean;
    deleteFlag: boolean;
    roles: string[];
    roleIds: string;
    roleNames: string;
}

export class Login extends BasicsClass {
    private axios: Axios;
    constructor() {
        super();
        this.axios = new Axios();
    }

    public async submitForm(param: login, callBack: Function, errCb: Function) {
        try {

            // 开发环境 测试账号 test123 密码 123456
            const loginTokenData: any = await this.axios.post<Result>('/admin/login/login', param);


            const { data } = loginTokenData || {}

            const {
                token: accessToken,
                // role
            } = data || {}

            accessToken && localStorage.setItem('token', accessToken)
            callBack(data);

        } catch (e: any) {

            if (e.message) {
                // this.vue.$message.error(e.message);
                errCb(e.message);
            }
        }
    }
    public logout(): void {
        // this.BasicGet('/animal/web/logout', {}, false, false);
    }
    public async getLoginVertifyCode(param: login, callBack: Function, errCb: Function) {
        try {
            const loginCodeData: any = await this.axios.post<Result>('/admin/login/send', {
                phone: param.phone,
            }).then(() => {
                callBack({
                    message: ''
                })
            }).catch((err: any) => {
                errCb(err ? err.message : '服务器异常');
            });


        } catch (e: any) {

            if (e.message) {
                // this.vue.$message.error(e.message);
                errCb(e.message);
            }

        }

    }

    public refreshToken(form: any, showLoading: boolean = false) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem('token')

            const token_expired = localStorage.getItem('tokne_expired')

            const nowTs = Math.floor(Date.now() / 1000)


            if (token) {
                if (token_expired && nowTs < Number(token_expired)) {
                    const token_expired_ts = Number(token_expired)

                    if (nowTs > token_expired_ts - 24 * 3600) { // token 有效期不到一天的情况下再刷新
                        const successCb = (res: any) => {
                            const {
                                data
                            } = res || {}

                            // console.log(res, 'res')
                            // console.log(data, 'data')
                            const {
                                token: accessToken,
                                end_time: token_expired_ts
                            } = data || {}

                            if (accessToken) {
                                localStorage.setItem('token', accessToken)

                                localStorage.setItem('tokne_expired', token_expired_ts)
                            }
                            resolve({
                                status: true
                            })
                        }
                        const failCb = (err: any) => {
                            reject(err)
                        }
                        this.BasicPost('/admin/login/refresh', { ...form }, false, false, false, successCb, failCb, showLoading);
                    } else {
                        resolve({
                            status: true
                        })
                    }
                } else {
                    resolve({
                        status: false
                    })
                }

            } else {
                resolve({
                    status: false
                })
            }
        })

    }

}
