import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './registerServiceWorker';
import '@/css/cssrest.styl';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/index.css';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import "@/assets/font/font.css";

// 图片预览器
Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 99999
  }
})
import scroll from 'vue-seamless-scroll';
Vue.use(scroll, { componentName: 'scroll-seamless' });
Vue.use(VXETable);
Vue.use(ElementUI);
import axios, { AxiosStatic } from 'axios';
Vue.use((v) => {
  // axios默认配置
  axios.defaults.timeout = 10000;   // 超时时间
  axios.defaults.baseURL = process.env.VUE_APP_BASE_API
  v.prototype.$axios = axios;
  v.prototype.$getResourceCodes = function (key: string) {
    // return this.$store.state.resourceCodes.includes(key);
    return true;
  };
});

declare module 'Vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
    $getResourceCodes: Function;
    $video: any;
  }
}

import Print from 'vue-print-nb'
Vue.use(Print);
Vue.config.productionTip = true;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
